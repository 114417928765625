import jsonp from 'jsonp';
import { encode } from './form';

export default function mailchimpSubmit(listUrl, userId, listId, values) {
  const url = `//${listUrl}/subscribe/post-json?u=${userId}&id=${listId}&${encode(values)}`;

  return new Promise((resolve, reject) => {
    jsonp(
      url,
      {
        param: 'c',
      },
      (err, data) => {
        if (err || data.result === 'error') {
          const error = data && data.result && data.result === 'error' ? data.msg : null;

          reject(new Error(error));
        }

        resolve(true);
      }
    );
  });
}
