import React, { Fragment } from 'react';
import uniqid from 'uniqid';
import classNames from 'classnames';

const DefList = ({ className, items }) => (
  <ul className={classNames('def-list', className)}>
    {items.map(({ title, url, text }) => {
      const itemBody = (
        <Fragment>
          <strong className="def-list__title">{title}</strong>
          <span className="def-list__text">{text}</span>
        </Fragment>
      );

      return (
        <li className="def-list__item" key={uniqid()}>
          {url ? (
            <a className="def-list__link" href={url} target="_blank" rel="noopener noreferrer">
              {itemBody}
            </a>
          ) : (
            itemBody
          )}
        </li>
      );
    })}
  </ul>
);

export default DefList;
