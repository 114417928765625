import React, { Fragment, useRef } from 'react';
import * as Yup from 'yup';
import { ErrorMessage } from 'formik';

import ajaxSubmit from '../../utils/ajax-submit';
import { showFormError } from '../../utils/form';
import { MESSAGE_REQUIRED_FIELD, MESSAGE_INVALID_EMAIL } from '../../utils/constants';
import Form from '../organisms/form';
import FormLayout from '../organisms/form-layout';
import withFormField from '../atoms/form-field';
import withNetlifyForm from '../organisms/netlify-form';
import TextInput from '../atoms/text-input';
import Button from '../atoms/button';

const FancyTextInput = withFormField(TextInput);
const NetlifyForm = withNetlifyForm(Form);

const validationSchema = Yup.object().shape({
  name: Yup.string().required(MESSAGE_REQUIRED_FIELD),
  email: Yup.string()
    .email(MESSAGE_INVALID_EMAIL)
    .required(MESSAGE_REQUIRED_FIELD),
  wordCount: Yup.string().required(MESSAGE_REQUIRED_FIELD),
  genre: Yup.string().required(MESSAGE_REQUIRED_FIELD),
  message: Yup.string().required(MESSAGE_REQUIRED_FIELD),
});

const initialValues = {
  name: '',
  email: '',
  wordCount: '',
  genre: '',
  message: '',
};

const formName = 'manuscript-critique';
const subject = 'Liminal Pages – Critique Enquiry';

const FormManuscriptCritique = ({
  background_image: backgroundImage,
  title,
  introduction,
  cta_button_text: ctaButtonText,
}) => {
  const ref = useRef();

  return (
    <FormLayout ref={ref} backgroundImage={backgroundImage} title={title} introduction={introduction}>
      <NetlifyForm
        container={ref}
        method="post"
        className=""
        name={formName}
        subject={subject}
        initialValues={initialValues}
        validationSchema={validationSchema}
        handleSubmit={values => {
          return ajaxSubmit(formName, subject, values);
        }}
        render={(errors, touched) => (
          <Fragment>
            <FancyTextInput
              errored={showFormError('name', errors, touched)}
              formName={formName}
              id="name"
              label="Name"
              wrapperClass="form__field form__field--half"
              name="name"
              required
              error={(name, cssClass) => <ErrorMessage className={cssClass} name={name} component="div" />}
            />
            <FancyTextInput
              errored={showFormError('email', errors, touched)}
              formName={formName}
              id="email"
              label="Email"
              wrapperClass="form__field form__field--half"
              type="email"
              name="email"
              required
              error={(name, cssClass) => <ErrorMessage className={cssClass} name={name} component="div" />}
            />
            <FancyTextInput
              errored={showFormError('wordCount', errors, touched)}
              formName={formName}
              id="count"
              label="Word count"
              wrapperClass="form__field form__field--half"
              name="wordCount"
              required
              error={(name, cssClass) => <ErrorMessage className={cssClass} name={name} component="div" />}
            />
            <FancyTextInput
              errored={showFormError('genre', errors, touched)}
              formName={formName}
              id="genre"
              label="Genre"
              wrapperClass="form__field form__field--half"
              name="genre"
              required
              error={(name, cssClass) => <ErrorMessage className={cssClass} name={name} component="div" />}
            />
            <FancyTextInput
              errored={showFormError('message', errors, touched)}
              formName={formName}
              id="message"
              label="Message"
              wrapperClass="form__field"
              name="message"
              required
              component="textarea"
              rows="5"
              error={(name, cssClass) => <ErrorMessage className={cssClass} name={name} component="div" />}
            />
          </Fragment>
        )}
        renderCta={disabled => (
          <Button type="submit" disabled={disabled}>
            {ctaButtonText}
          </Button>
        )}
      />
    </FormLayout>
  );
};

FormManuscriptCritique.defaultProps = {
  cta_button_text: 'Send',
};

export default FormManuscriptCritique;
