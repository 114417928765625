import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form as FormikForm } from 'formik';
import classNames from 'classnames';

import { scrollToRef } from '../../utils/scroll-to-ref';
import { MESSAGE_FORM_SUBMIT_GENERAL_ERROR, MESSAGE_FORM_SUBMIT_GENERAL_SUCCESS } from '../../utils/constants';

const Form = ({
  container,
  name,
  className,
  initialValues,
  validationSchema,
  handleSubmit,
  render,
  renderCta,
  fieldWrapper,
  botFieldLabel,
  botFieldId,
  botFieldName,
  children,
  successMessage,
  errorMessage,
  parseError,
  subject,
  ...rest
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        const { setSubmitting, setStatus } = actions;

        if (container) {
          scrollToRef(container, {
            visibilityCheck: false,
          });
        }

        return handleSubmit(values)
          .then(() => {
            setStatus({ submitted: true, errored: false, message: successMessage });
            setSubmitting(false);
          })
          .catch(e => {
            console.error(e);
            setStatus({ submitted: false, errored: true, message: parseError(e, errorMessage) });
            setSubmitting(false);
          });
      }}
      render={({ isSubmitting, errors, touched, status, setFieldValue, values, submitCount }) => {
        const rootClass = 'form';

        const fields = render ? render(errors, touched, setFieldValue, values) : children;
        const ctaDisabled = submitCount > 0 && Object.keys(errors).length > 0 && Object.keys(touched).length > 0;
        const cssClass = classNames(rootClass, {
          [className]: className,
          [`${rootClass}--is-submitting`]: isSubmitting,
          [`${rootClass}--submitted`]: status && status.submitted,
        });

        return (
          <FormikForm name={name} className={cssClass} noValidate {...rest}>
            <div className="form__inner">
              {status && status.errored && status.message && (
                <div className="form__error" dangerouslySetInnerHTML={{ __html: status.message }} />
              )}
              {status && status.submitted && status.message && (
                <div className="form__success" dangerouslySetInnerHTML={{ __html: status.message }} />
              )}
              <div className="form__body">
                {fieldWrapper ? <div className="form__fields">{fields}</div> : fields}
                {renderCta && renderCta(ctaDisabled)}
              </div>
            </div>
            <input type="hidden" name="form-name" value={name} />
            {subject && <input type="hidden" name="subject" value={subject} />}
            <p className="form__honey" aria-hidden="true">
              <label htmlFor={botFieldId}>{botFieldLabel}</label>
              <input id={botFieldId} name={botFieldName} tabIndex="-1" />
            </p>
          </FormikForm>
        );
      }}
    />
  );
};

Form.propTypes = {
  container: PropTypes.shape({ current: PropTypes.any }),
  name: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  validationSchema: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  render: PropTypes.func,
  renderCta: PropTypes.func,
  fieldWrapper: PropTypes.bool,
  botFieldLabel: PropTypes.string,
  botFieldId: PropTypes.string,
  botFieldName: PropTypes.string,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  parseError: PropTypes.func,
  subject: PropTypes.string,
};

Form.defaultProps = {
  container: null,
  className: null,
  render: null,
  renderCta: null,
  fieldWrapper: true,
  botFieldLabel: 'Don’t fill this out if you are human:',
  botFieldId: 'bot-field',
  botFieldName: 'bot-field',
  successMessage: MESSAGE_FORM_SUBMIT_GENERAL_SUCCESS,
  errorMessage: MESSAGE_FORM_SUBMIT_GENERAL_ERROR,
  parseError: (message, defaultMessage) => defaultMessage,
  subject: null,
};

export default Form;
