import React, { useState } from 'react';

const withFormField = Component => {
  return ({
    wrapperClass,
    formName,
    id,
    name,
    label,
    required,
    className,
    placeholder,
    errored,
    error,
    hideErrorMessage,
    staticErrorMessage,
    ...rest
  }) => {
    const [hasValue, updateValue] = useState(false);

    let rootClass = 'form-field';
    let cssClass = rootClass;

    if (hasValue) {
      cssClass = `${cssClass} ${rootClass}--has-value`;
    }

    if (errored) {
      cssClass = `${cssClass} ${rootClass}--invalid`;
    }

    if (hideErrorMessage) {
      cssClass = `${cssClass} ${rootClass}--hide-error-message`;
    }

    if (staticErrorMessage) {
      cssClass = `${cssClass} ${rootClass}--static-error-message`;
    }

    if (wrapperClass) {
      cssClass = `${cssClass} ${wrapperClass}`;
    }

    let fieldPlaceholder = placeholder || label;

    if (required) {
      fieldPlaceholder = `${fieldPlaceholder} *`;
    }

    const fieldId = `${formName}-${id}`;

    return (
      <div className={cssClass}>
        <Component
          id={fieldId}
          name={name}
          className={`form-field__input${className ? ` ${className}` : ''}`}
          required={required}
          placeholder={fieldPlaceholder}
          onInput={({ target }) => {
            const { value } = target;

            if (value.length > 0) {
              return updateValue(true);
            }

            return updateValue(false);
          }}
          {...rest}
        />
        <label className="form-field__label" htmlFor={fieldId}>
          {label}
          {required && ' *'}
        </label>
        {errored && error(name, 'form-field__error')}
      </div>
    );
  };
};

export default withFormField;
