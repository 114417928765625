import { Field } from 'formik';
import React from 'react';

const TextInput = ({ className, type, name, id, ...props }) => {
  return (
    <Field name={name} id={id} className={`text-input${className ? ` ${className}` : ''}`} type={type} {...props} />
  );
};

TextInput.defaultProps = {
  type: 'text',
};

export default TextInput;
