import React from 'react';
import FormNewsletterSignup from '../molecules/form-newsletter-signup';
import FormContact from '../molecules/form-contact';
import FormManuscriptCritique from '../molecules/form-manuscript-critique';
import FormQuote from '../molecules/form-quote';
import FormEditorialApprisal from '../molecules/form-editorial-apprisal';
import FormBuy from '../molecules/form-buy';

const forms = [
  {
    id: 230,
    component: FormNewsletterSignup,
  },
  {
    id: 379,
    component: FormNewsletterSignup,
  },
  {
    id: 436,
    component: FormNewsletterSignup,
  },
  {
    id: 438,
    component: FormNewsletterSignup,
  },
  {
    id: 439,
    component: FormNewsletterSignup,
  },
  {
    id: 700,
    component: FormBuy,
  },
  {
    id: 702,
    component: FormBuy,
  },
  {
    id: 703,
    component: FormBuy,
  },
  {
    id: 704,
    component: FormBuy,
  },
  {
    id: 346,
    component: FormContact,
  },
  {
    id: 611,
    component: FormManuscriptCritique,
  },
  {
    id: 612,
    component: FormQuote,
  },
  {
    id: 613,
    component: FormEditorialApprisal,
  },
  {
    id: 1481,
    component: FormBuy,
  },
  {
    id: 1606,
    component: FormBuy,
  },
  {
    id: 1614,
    component: FormBuy,
  },
];

const FormRenderer = ({ form }) => {
  const { wordpress_id: id, ...rest } = form;
  const RenderForm = forms.find(formObject => formObject.id === id);

  if (RenderForm) {
    return <RenderForm.component {...rest} />;
  }

  return null;
};

export default FormRenderer;
