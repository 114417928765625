import React from 'react';
import LinkButton from '../atoms/link-button';
import FormLayout from '../organisms/form-layout';

const FormBuy = ({
  background_image: backgroundImage,
  title,
  introduction,
  cta_button_text: ctaButtonText,
  id,
  Information: information,
  purchase_url: purchaseUrl,
}) => {
  return (
    <FormLayout
      className="form-buy"
      backgroundImage={backgroundImage}
      title={title}
      introduction={introduction}
      id={id}
      information={information}
    >
      {purchaseUrl && (
        <LinkButton url={purchaseUrl} external>
          {ctaButtonText}
        </LinkButton>
      )}
    </FormLayout>
  );
};

FormBuy.defaultProps = {
  id: 'registration',
};

export default FormBuy;
