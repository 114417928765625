import React, { forwardRef } from 'react';
import Img from 'gatsby-image';
import classNames from 'classnames';
import getGraphqlImage from '../../utils/get-graphql-image';
import DefList from '../atoms/def-list';

const FormLayout = React.forwardRef(
  ({ className, children, title, subtitle, introduction, backgroundImage, id, information }, ref) => {
    const imageSizes = getGraphqlImage(backgroundImage);

    const informationItems =
      information &&
      information.map(({ title: label, value }) => ({
        title: label,
        text: value,
      }));

    return (
      <section className={classNames('form-layout', className)} id={id} ref={ref}>
        <div className="form-layout__inner container">
          <div className="form-layout__content-wrapper">
            <div className="form-layout__content">
              <div className="form-layout__content-inner">
                <div className="form-layout__header">
                  <h2 className="form-layout__title">{title}</h2>
                  {subtitle && <strong className="form-layout__subtitle">{subtitle}</strong>}
                </div>
                {introduction && (
                  <div className="form-layout__introduction" dangerouslySetInnerHTML={{ __html: introduction }} />
                )}
                {informationItems && (
                  <div className="form-layout__information">
                    <DefList className="form-layout__information-list" items={informationItems} />
                  </div>
                )}
                {children}
              </div>
            </div>
          </div>
        </div>
        <Img className="form-layout__background" src={imageSizes.src} fluid={imageSizes} />
      </section>
    );
  }
);

FormLayout.defaultProps = {
  id: null,
};

export default FormLayout;
