import { Field } from 'formik';
import React from 'react';
import classNames from 'classnames';
import Button from '../atoms/button';

const FileInput = ({ wrapperClass, formName, label, className, name, id, onChange, buttonText, file, ...props }) => {
  const fieldId = `${formName}-${id}`;

  return (
    <div className={classNames('file-input', wrapperClass)}>
      <label className="file-input__label" htmlFor={fieldId}>
        <span className="file-input__label-body">{label}</span>
        <Field
          render={({ field }) => {
            const { value } = field;

            return (
              <input
                name={name}
                id={fieldId}
                type="file"
                className={classNames('file-input__input', className)}
                onChange={event => {
                  if (onChange) {
                    const file = event.currentTarget.files && event.currentTarget.files[0];
                    onChange(file);
                  }
                }}
              />
            );
          }}
          {...props}
        />
        <div className="file-input__controls">
          <span className="file-input__button button">{buttonText}</span>
          {file && file.name && <span className="file-input__file-info">{file.name}</span>}
        </div>
      </label>
    </div>
  );
};

FileInput.defaultProps = {
  filename: 'test.txt',
  buttonText: 'Choose file',
};

export default FileInput;
