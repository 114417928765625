import React from 'react';

const withNetlifyForm = Component => {
  return ({ name, ...rest }) => {
    return (
      <Component name={name} action={`/${name}`} data-netlify="true" data-netlify-honeypot="bot-field" {...rest} />
    );
  };
};

export default withNetlifyForm;
